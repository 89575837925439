import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { Container } from '@web-app/components/Container'
import { classNamesBlueberryLg } from '@web-app/components/buttons/Button'

export const HeroEarn = ({
  title,
  description,
  cta,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClickEvent = () => {},
}: {
  title: React.ReactNode
  description: React.ReactNode
  cta?: string
  handleClickEvent?: () => void
}) => {
  return (
    <Container>
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col justify-center py-8 md:w-2/3 md:py-12">
          <h1 className="font-montserrat text-3xl font-bold sm:text-4xl md:text-6xl">
            {title}
          </h1>
          <p className="my-4 text-xl font-semibold md:my-6 md:text-2xl">
            {description}
          </p>
          <div className="flex">
            <Link
              onClick={handleClickEvent}
              className={classNamesBlueberryLg}
              href="/auth/register"
            >
              {cta || <>Get Started</>}
            </Link>
          </div>
        </div>
        <div className="mt-8 flex items-end justify-center md:ml-8 md:mt-0">
          <Image
            alt="ATM app"
            src="/img/hero_atm_instant_cash_50@3x.png"
            width={505}
            height={438}
            loading="eager"
            priority={true}
          />
        </div>
      </div>
    </Container>
  )
}
