import { BackgroundSolid } from '@web-app/components/background/BackgroundSolid'
import { LandingHeader } from '@web-app/components/layout/landing/LandingHeader'
import { LandingMain } from '@web-app/components/layout/landing/LandingMain'
import { LandingFooter } from '@web-app/components/layout/landing/LandingFooter'

export function LandingLayout({
  children,
  hero,
}: {
  children: React.ReactNode
  hero?: React.ReactNode
}) {
  return (
    <div className="flex min-h-screen flex-col">
      <BackgroundSolid className="bg-off-white text-black">
        <LandingHeader />
        {hero}
      </BackgroundSolid>
      <LandingMain className="flex-grow">{children}</LandingMain>
      <LandingFooter />
    </div>
  )
}
