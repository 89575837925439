import { useContext } from 'react'
import { EventTrackingContext } from '@web-app/context/EventTrackingContext'

export const useEventTracking = () => {
  const context = useContext(EventTrackingContext)
  if (context === undefined) {
    throw new Error(
      'useEventTracking must be used within a EventTrackingProvider'
    )
  }
  return context
}
