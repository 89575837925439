import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Container } from '@web-app/components/Container'
import { classNamesBlueberryLg } from '@web-app/components/buttons/Button'

interface BottomCallToActionProps {
  title?: string
  description?: string
  cta?: string
  handleClickEvent?: () => void
  hideDescription?: boolean
  customDescription?: React.ReactNode
}

export const BottomCallToAction: React.FC<BottomCallToActionProps> = ({
  title,
  description,
  cta,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleClickEvent = () => {},
  hideDescription = false,
  customDescription,
}) => {
  return (
    <Container className="bg-white text-black">
      <div className="flex flex-col items-center md:flex-row md:items-end">
        <div className="self-center px-4 pt-12 md:w-2/3 md:py-12">
          <h3 className="font-montserrat text-3xl font-bold sm:text-4xl md:text-5xl">
            {title || (
              <>
                ATM <span className="whitespace-pre">Instant Cash™</span>
              </>
            )}
          </h3>
          {!hideDescription && (
            <p className="mt-4 text-2xl font-semibold md:mt-6 md:text-2xl">
              {description ||
                'Get up to $50*, no interest, no credit check, no late fees.'}
            </p>
          )}
          {customDescription}
          <p className="mb-4 mt-1 text-xs md:mb-6">
            *Eligibility is based on review and approval. Not all applicants
            will be eligible for ATM Instant Cash™. Transfer fees may apply.
          </p>
          <div className="flex">
            <Link
              onClick={handleClickEvent}
              className={classNamesBlueberryLg}
              href="/auth/register"
            >
              {cta || <>Get Started</>}
            </Link>
          </div>
        </div>
        <div className="mt-8 flex items-end justify-center">
          <Image
            alt="ATM app"
            src="/img/bottom_desktop@3x.png"
            width={819}
            height={418}
          />
        </div>
      </div>
    </Container>
  )
}
