import Image from 'next/image'
import Link from 'next/link'
import { Container } from '@web-app/components/Container'
import { useSession } from '@web-app/hooks/useSession'
import {
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
} from '@web-app/components/SocialIcons'

import styles from './landing.module.scss'

export function LandingFooter() {
  const { sessionData } = useSession()
  const user = sessionData?.user
  const isLoggedIn = Boolean(user)

  return (
    <footer className={`${styles.footer} bg-blueberry-1000 text-white`}>
      <Container>
        <div className="flex flex-col gap-4 py-8 md:flex-row md:justify-between md:py-16">
          <div className="flex flex-grow flex-col text-sm md:w-2/3 lg:w-1/2">
            <div className="pb-4">
              <Image
                src="/svg/logo-white.svg"
                alt="ATM.com"
                width={100}
                height={35}
              />
            </div>
            <div className="pb-4">
              Copyright &copy; {new Date().getFullYear()} ATM.com
              <sup>&reg;</sup> | All rights reserved.
              <br />
              None of your data will ever be used without your permission.
            </div>
            <div className="border-blueberry-100  mb-2 flex space-x-4 border-b pb-4 pt-2 md:mb-0 md:border-b-0">
              <Link
                href="https://www.linkedin.com/company/atmdotcom/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blueberry-200 transition-colors"
              >
                <LinkedInIcon className="h-6 w-6" fill="currentColor" />
              </Link>
              <Link
                href="https://www.facebook.com/atmapp-105223718404977/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blueberry-200 transition-colors"
              >
                <FacebookIcon className="h-6 w-6" fill="currentColor" />
              </Link>
              <Link
                href="https://www.instagram.com/atmapp"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blueberry-200 transition-colors"
              >
                <InstagramIcon className="h-6 w-6" fill="currentColor" />
              </Link>
              <Link
                href="https://twitter.com/atmdotcom"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blueberry-200 transition-colors"
              >
                <TwitterIcon className="h-6 w-6" fill="currentColor" />
              </Link>
            </div>
          </div>
          <div className="items-start py-4 text-sm font-bold">
            <div className="grid grid-cols-2 items-center gap-4">
              <Link
                className="hover:text-blueberry-200 transition-colors"
                href="https://atm.com/privacy-policy/"
              >
                Privacy Policy
              </Link>
              <Link
                className="hover:text-blueberry-200 transition-colors"
                href="https://atm.com/terms-of-service/"
              >
                Terms of Service
              </Link>
              <Link
                className="hover:text-blueberry-200 transition-colors"
                href="https://atm.com/legal-disclaimers/"
              >
                Legal Disclaimers
              </Link>
              <Link
                className="hover:text-blueberry-200 transition-colors"
                href="https://atm.com/privacy-policy/#California-Privacy-Rights"
              >
                Do Not Sell My Personal Information
              </Link>
              {isLoggedIn && (
                <Link
                  className="hover:text-blueberry-200 transition-colors"
                  href="/auth/logout"
                >
                  Logout
                </Link>
              )}
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}
