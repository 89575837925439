import React from 'react'
import styles from './BannerTextUnderline.module.css'

export const BannerTextUnderline: React.FC = () => {
  return (
    <div className="bg-background-tertiary text-type-primary-alt px-4 py-8 md:py-12 ">
      <h2
        className={`${styles.title} font-montserrat mx-auto max-w-[500px] text-center text-3xl font-bold md:max-w-[700px] md:text-5xl`}
      >
        An app designed to give you{' '}
        <span className={styles.underline}>more Cash</span>
      </h2>
    </div>
  )
}
