import clsx from 'clsx'
import styles from './landing.module.scss'

export function LandingMain({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return <main className={clsx(styles.main, className)}>{children}</main>
}
