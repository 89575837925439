import React from 'react'
import Image from 'next/image'
import styles from './TestimonialsGrid.module.css'

const testimonials = [
  {
    title: 'Great App!',
    rating: 5,
    review:
      "It's a great app that allows you to earn a small amount of passive income and you can even invest that earned money in the market. Lets grow this together! 🐜💪💯",
    reviewer: 'John F',
  },
  {
    title: 'Love the ant colony',
    rating: 5,
    review:
      'I love being part of the ant colony, it save a little extra cash for small items that you might need like toiletries and showering.',
    reviewer: 'Lorren W',
  },
  {
    title: 'A lot of opportunities',
    rating: 5,
    review:
      'There are so many opportunities to earn money from engaging with their partners and some of the rewards are very generous.',
    reviewer: 'Luke S',
  },
  {
    title: 'This is the best app',
    rating: 5,
    review:
      "I've tried several different apps and services that pay incentives for accepting brand offers, ATM is by far the best.",
    reviewer: 'Angela F',
  },
  {
    title: 'I love ATM',
    rating: 5,
    review:
      "I love what ATM is trying to do here; and I'm excited to see where we end up! ",
    reviewer: 'Devin W',
  },
  {
    title: 'Educational',
    rating: 5,
    review:
      "It's a great app! It really break things down so you can understand and it goes in depth on what you should do. So I'm my opinion just give it a shot cuz you only live once.",
    reviewer: 'Jared T',
  },
  {
    title: 'Actually good app',
    rating: 5,
    review:
      "I earn about $1.50 a week. Sure, it doesn't sound like a lot, but it adds up, and I get introduced to new brands, and the layout is user-friendly, what's not to like? It's very easy and fun.",
    reviewer: 'Sarah S',
  },
  {
    title: 'Easy to use',
    rating: 5,
    review:
      "I'm very happy with the app. I've been using it for about 4 months & made a bit over $43.00. I'm just letting it accumulate for now. I think it's the easiest app to make money I've come across.",
    reviewer: 'Candace L',
  },
]

export const TestimonialsGrid: React.FC = () => {
  return (
    <div className="bg-background-primary overflow-hidden py-24 sm:py-32">
      <div className="mx-auto px-0">
        <div className="mx-auto max-w-7xl px-4 text-center">
          <h2 className="text-blueberry-700 text-xl font-semibold leading-8 tracking-tight md:text-2xl">
            Testimonials
          </h2>
          <p className="font-montserrat mx-auto mt-2 max-w-[700px] text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
            We&apos;ve helped thousands of people earn more money!
          </p>
        </div>
        <div className="mx-auto mt-4 overflow-x-auto overflow-y-hidden scroll-smooth py-4 lg:mx-0">
          <div className={`${styles.scrollContainer} flex gap-4`}>
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="h-[300px] min-w-[300px] max-w-[300px] flex-none"
              >
                <figure className="flex h-full flex-col justify-between rounded-2xl bg-white p-8">
                  <blockquote>
                    <h3 className="font-bold text-gray-900">
                      {testimonial.title}
                    </h3>
                    <div className="my-2 flex">
                      {[...Array(5)].map((_, i) => (
                        <Image
                          key={i}
                          src="/svg/star-filled.svg"
                          alt="star"
                          width={20}
                          height={20}
                          className="text-yellow-400"
                        />
                      ))}
                    </div>
                    <p className="overflow-hidden text-sm text-gray-900">
                      {testimonial.review}
                    </p>
                  </blockquote>
                  <figcaption className="mt-4">
                    <div className="font-semibold text-gray-900">
                      - {testimonial.reviewer}
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
