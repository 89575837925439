import Image from 'next/image'
import Link from 'next/link'
import { useEventTracking } from '@web-app/hooks/useEventTracking'
import { classNamesBlueberrySm } from '@web-app/components/buttons/Button'
import styles from './landing.module.scss'

export function LandingHeader() {
  const { trackEvent } = useEventTracking()

  return (
    <header className={styles.header}>
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-4 sm:p-6 lg:px-8"
        aria-label="Global"
      >
        <Link href="/" className="-m-1.5 p-1.5">
          <span className="sr-only">ATM.com</span>
          <Image
            className="h-12 w-auto"
            src="/svg/logo.svg"
            alt="ATM.com"
            width={138}
            height={48}
            priority={true}
            loading="eager"
          />
        </Link>
        <div className="flex lg:gap-x-12">
          <Link
            onClick={() =>
              trackEvent('click_header_sign_up', { comp: 'LandingHeader' })
            }
            href="/auth/register"
            className={classNamesBlueberrySm}
          >
            Sign up
          </Link>
        </div>
      </nav>
    </header>
  )
}
