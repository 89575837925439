import React from 'react'

export function BackgroundSolid({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div className={className ?? 'bg-blueberry-700 text-white'}>{children}</div>
  )
}
